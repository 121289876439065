export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_RAIDERS = "GET_RAIDERS";
export const GET_FORUMS = "GET_FORUMS";
export const SET_USER_ADMIN_STATUS = "SET_USER_ADMIN_STATUS";
export const GET_SINGLE_FORUM = "GET_SINGLE_FORUM";
export const GET_PROFILE_INFO = "GET_PROFILE_INFO";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_FORUM_DATA = "GET_ALL_FORUM_DATA";
export const RETURN_IMAGE_NAMES = "RETURN_IMAGE_NAMES";
export const GET_ALL_MAIN_DATA = "GET_ALL_MAIN_DATA";
export const VALIDATE_RECAPTCHAV2 = "VALIDATE_RECAPTCHAV2";
export const VERIFY_CHARACTER_EXISTS = "VERIFY_CHARACTER_EXISTS";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const GET_GUILD_INFORMATION = "GET_GUILD_INFORMATION";
